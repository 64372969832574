<template>
  <b-list-group flush class="my--3">
    <b-list-group-item class="px-0"
        v-for="user in users"
        :key="user.id"
    >
      <b-row align-v="center">
        <b-col cols="auto">
          <!-- Avatar -->
          <a href="#" class="avatar rounded-circle">
            <b-img alt="Image placeholder" src="img/brand/favicon.png" />
          </a>
        </b-col>
        <b-col class="ml--2">
          <h4 class="mb-0">
            <router-link :to="{name: 'user-details', params: {id: user._id}}">{{user.name}}</router-link>
          </h4>
          <span :class="`text-success`" v-if="user.active">● </span>
          <span :class="`text-danger`" v-else>● </span>
          <small v-if="user.active">Active</small>
          <small v-else>Inactive</small>
        </b-col>
        <!-- <b-col cols="auto">
          <base-button type="primary" size="sm">Add</base-button>
        </b-col> -->
      </b-row>
    </b-list-group-item>
  </b-list-group> 
</template>
<script>

import * as _ from 'lodash';

export default {
  name: 'user-list',
  data() {
    return {
      users: [
        {
          id: 1,
          img: 'img/theme/team-1.jpg',
          name: 'John Michael',
          status: 'Online',
          statusType: 'success'
        },
        {
          id: 2,
          img: 'img/theme/team-2.jpg',
          name: 'Alex Smith',
          status: 'In a meeting',
          statusType: 'danger'
        },
        {
          id: 3,
          img: 'img/theme/team-3.jpg',
          name: 'Samantha Ivy',
          status: 'Offline',
          statusType: 'danger'
        },
        {
          id: 4,
          img: 'img/theme/team-4.jpg',
          name: 'John Michael',
          status: 'Online',
          statusType: 'success'
        }
      ]
    };
  },
  created: async function () {
    this.findUsers();
  },
  methods: {
    async findUsers() {
      const that = this;
      const response = await that.$http.get("/user", {
        params: {
          customer: that.$store.state.user.customer._id
        }
      });

      that.users = _.slice(response.data, 0, 4);
    },
  }
};
</script>
<style></style>
