<template>
  <card gradient="light">
    <b-row align-v="center"  class="justify-content-between">
      <b-col>
        <!-- <img src="img/icons/cards/bitcoin.png" alt="Image placeholder"> -->
      </b-col>
      <b-col md="auto" >
        <b-badge size="lg" variant="success">Active</b-badge>
      </b-col>
    </b-row>
    <div class="my-4">
      <span class="h6 surtitle text-light">
        Organization
      </span>
      <div class="h1 text-white">
        {{ myCustomer.name }}
      </div>
    </div>
    <b-row >
      <b-col>
        <span class="h6 surtitle text-light">Name</span>
        <span class="d-block h3 text-white">
          {{ myUser.name || myUser.email }}
        </span>
      </b-col>
    </b-row>
    <b-row v-if="myUser.systemRoles">
      <b-col>
        <span class="h6 surtitle text-light">System Roles</span>
        <span class="d-block h3 text-white">
          {{ roles }}
        </span>
      </b-col>
    </b-row>
  </card>
</template>

<script>

import { mapActions, mapState } from "vuex";

import * as _ from "lodash";

export default {
  // mixins: [BaseDialogMixin, BaseAlertMixin],
  data: function () {
    return {
      userForm: {},
      passwordForm: {},
      roles: '',
      myUser: {},
      myCustomer: {}
    };
  },
  computed: {
    ...mapState("user", ["user", "myGroups"]),
  },
  created: async function () {
    let that = this;
    // that.showLoadingDialog("Fetching information!");

    const response = await that.$http.get(`/user/me`);
    that.myUser = response.data;

    that.userForm = _.cloneDeep(that.myUser);

    const roles = [];
    _.each(that.myUser.systemRoles, (role) => {
      roles.push(role.split('-').join(' '))
    });
    that.roles = roles.join(', ');

    that.myCustomer = await that.$http.get(`/customer/${that.myUser.customer}`).then(response => response.data);

    // that.hideLoadingDialog();
  },
  methods: {
    ...mapActions("user", ["setUser", "getUser", "getMyGroups", "setMyGroups"]),
    async changePassword() {
      const that = this;
      const response = await that.$http.patch(`/change-password/${that.myUser._id}`, that.passwordForm);
      that.passwordForm = {};
    },
    async updateUser() {
      const that = this;

      try {
        const response = await that.$http.patch(`/user/${that.myUser._id}`, that.userForm);
        that.myUser = response.data;
      } catch (error) {
        return that.$notify({
          text: error.response.data.message,
          duration: 10000,
          type: "error"
        });
      }

      const roles = [];
      _.each(that.myUser.systemRoles, (role) => {
        roles.push(role.split('-').join(' '))
      });
      that.roles = roles.join(', ');

      that.$notify({
        text: "Saved.",
        duration: 10000,
        type: "success"
      });
    }
  },
};
</script>

<style></style>